.dataTables_wrapper .dataTable thead th{
    color: #000000 !important;
    
}
table{
    text-transform: uppercase;
}

div.dataTables_wrapper div.dataTables_length select{
    color: #3699FF !important;
    background-color: #E1F0FF !important;
    border: 0 !important;
}

thead{
    text-align-last:center;
    white-space: nowrap;
}

.card-toolbar a.btn.btn-icon{

    border-color: transparent;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.card-toolbar a.btn.btn-icon i.flaticon-add{   
    padding-right: 10px;
}

.card-toolbar a.btn.btn-icon i.flaticon-add div{
    padding-right: 0px;
    padding-left: 0px;
}

div.dataTables_wrapper div.dataTables_info {
    color: #80808F!important;
    margin-top:7px;
    }

.logo{
    width: 50px;
    height: 50px;    
}