@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

.select-search {
    position: relative;
    box-sizing: border-box; 
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit; 
}

.select-search__value {
    position: relative;
    z-index: 1; 
}

.select-search__value::after {
    content: '';
    display: inline;
    position: absolute;
    top: calc(50% - 5px);
    right: 10px; 
}

.select-search__input {
    display: block;
    height: calc(1.5em + 1.3rem + 2px);
    width: 100%;
    padding: 0 11px;
    background: #fff;
    border: 1px solid #E5EAEE;
    outline: none;
    font-size: 1rem;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 1.5;
    -webkit-appearance: none;
    font-weight: 400;
    color: #464e5f;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem #464e5f;
    
}

.select-search__options {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.select-search__row:not(:first-child) {
    border-top: 0.2px solid #eee;
}

.select-search__option {
    display: block;
    height: auto;
    width: 100%;
    padding: 6px 6px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left; 
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #ecf0f3;
    color: #464e5f;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #f3f6f9;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #f3f6f9;
    color: #464e5f;
}

.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
}

.select-search.is-disabled {
    opacity: 0.5;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #c1c1c1;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 3;
    top: 39px; /*Código para subir o bajar las opciones del selectsearch  */    
    right: 1px;
    left: 2px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px; 
}