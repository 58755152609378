.comunicados{
    background-color: white;
    padding: 20px;
}

.comunicados-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 3rem;
}

.comunicado{
    margin:1rem ;
}

.btn-comunicado{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.btn-comunicado a button{
    border: none;
    background-color: #F2F2F2;
    border-radius: 10px;
}

.btn-comunicado a button:hover{
    transition: 0.8s;
    background-color: #E5E5E5;

}

.modal-comunicado{
    width: 150px;
    background-color: red;
}

.filtros-comunicados{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3rem;
}

.filtros-comunicados div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.filtros-comunicados div button{
    border: none;
    background-color: #F2F2F2;
    border-radius: 10px;
    margin-top: 1rem;
}

.filtros-comunicados div button:hover{
    background-color: #E5E5E5;
}

.btn-subir {
    // display: flex;
    // justify-content: flex-end;
    text-align: left;
    margin-left: 80%;
    width: 80px;
    height: 50px;
}

.btn-subir button{
    border: none;
    padding: 1rem;
    border-radius: 8px;
    color: #388E3C ;
    font-weight: bold;
}

.btn-subir button:hover{
    background-color: #388E3C;
    color: white;
    
}

.container-modal{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.container-modal div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-gray label{
    font-weight: 500;
}

.input-gray input{
    border: none;
    background-color: #F2F2F2;
    width: 300px;
    height: 30px;
    
}

.input-gray input:focus{
    outline: none;
}
/* api/comunicado */

.date{
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 1rem;
}

.send-comunicado{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.file input{
    display: none;
}

.file label{
    border: none;
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 1rem;
    margin-top: 1rem;
}

.file label:hover{
    background-color: #E5E5E5;
}

.file-name{
    color:#2171c1;
    font-weight: bold;
    background-color: #e1f0ff;
    width: 150px;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
}
