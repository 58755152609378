.title-reporte-ventas{
    color: #535353;
    font-size: 20px;
    font-weight: bold;
    strong{
        font-size: 30px;
        margin-right: 10px;
    }
}

.text-reporte-ventas{
    margin-top: 0.5rem;
    color: #535353;
    font-size: 15px;
}


.editor-class{
    border: 2px solid #F3F6F9!important;
    padding: 2px !important;
    border-radius: 2px !important;
}
.colorInein{
    color: #D8005A;
}
.colorIMAzul{
    color: #7096c1;
}