.table{
    &__wrap{
        display: block;
        max-width: 99%;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0.5rem 0px;
    }
    &__container{
        width: 100%;
        border-spacing: 0;
        tbody{
            tr{
                &:not(:last-child){
                    border: solid;
                    border-width: 0 0 0.5px 0;
                    border-color: $dark-blue;
                }
            }
        }
        th{
            background-color: $dark-blue-80;
            color: #fafafa;
            &:not(:first-child):not(:last-child){
                border: solid;
                border-width: 0 2px;
                border-color: $bone;
            }
        }
        td{
            width: auto;
            color: $dark-blue;
            &:first-child{
                width: 1%;
            }
            &.collapse{
                width: 0.0000000001%;
            }
        }
        th, td{
            padding: 0.25rem 1rem;
        }
        tr:nth-child(odd) {background: #F0F0F0}
        tr:nth-child(even) {background: #FFFFFF}
        .logo{
            max-width: 200px;
            height: auto;
        }
    }
}

.hidding-columns{
    &__single{
        margin-top: 1rem;
        display: flex;
        .checkbox{
            padding-right: 1rem;
        }
    }
}

.verde{
    background-color: #e3f8f7;
}

.rojo{
    background-color: #fee9ec;
}

.gris{
    background-color: #ecf0f3;
}
.blanco{
    background-color: #ffffff;
}
.morado{
    background-color: #f4ebf7;
}

.amarillo{
    background-color: #fdfdee;
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
    #kt_datatable_compras, #kt_datatable_ventas, #kt_datatable_egresos, #kt_datatable_partidas, #kt_datatable_contratos { 
            table{
            overflow-x:auto;
            }	
    }
}

.datatables-net{
    @include down-screen($md){
        table{
            width: 100%;
        }
    }
}

.table_nominas{
    @include screen($md){
        min-height: 30vh;
        padding-bottom: 8rem;
    }
}

input#empleado,
input#proyecto {
    @include down-screen($md) {
        width: 205px;
        padding-left: 6px;
    }
    @include screen ($md) {
        min-width: 205px;
    }
    @include screen ($lg) {
        min-width: 170px;
    }
    @include screen ($xl) {
        min-width: 300px;
    }
}
input#salario_hr,
input#hr_trabajadas,
input#salario_hr_extra,
input#hr_extra,
input#nominImss,
input#restanteNomina,
input#extras,
div#total {
    @include down-screen($md) {
        width: 72px;
        padding-left: 6px;
    }
    @include screen ($md) {
        min-width: 72px;
    }
    @include screen ($lg) {
        min-width: 67px;
    }
    @include screen ($xl) {
        min-width: 72px;
    }
}
.mt-2.pb-3.font-size-sm, th.pb-0.border-bottom-0.font-size-sm, .mt-2.pb-3{
    @include down-screen($md) {
        text-align: center;
        font-size: 12px;
    }
    @include screen ($md) {
        text-align: center;
        font-size: 12px;
    }
    @include screen ($lg) {
        text-align: center;
        font-size: 12px;
    }
    @include screen ($xl) {
        text-align: center;
        font-size: 12px;
    }
}
th{
    @include screen($lg) {
        padding: 3px ;
        width: 72px;
    }
}

.desc-big{
    @include screen($lg){
        width: 40vw;
    }
    @include screen($md){
        width: 30vw;
    }
}
tr.selected{
    td{
        background-color: #FFF4DE!important;
    }
}

.dataTables_processing{
    /* background-color: red; */
    /* background-color:#E5EAEE!important; */
    background-color: transparent!important;
    height: 100%!important;
    z-index: 1!important;
    top: 0!important;
    left: 0!important;
    width: 100%!important;
    margin-left: 0px!important;
    margin-top: 0px!important;
    padding: 1em!important;
    div{
        /* padding: 1em; */
        .spinner-border{
            position: fixed;
        }
    }
}
#table-tareas{
    @include down-screen($xxxs) {
        div#titulo, div#tags-date, div#tags{
            text-align:center;
        }
    }
    @include screen($xxs) {
        div#titulo, div#tags-date, div#tags{
            text-align:center;
        }
    }
    @include screen($xs) {
        div#titulo{
            text-align:center;
        }
    }
    @include screen($sm) {
        div#titulo, div#tags-date, div#tags{
            text-align:justify;
        }
        div#tags-date{
            text-align:right;
        }
        
    }
    @include screen($lg) {
        .col-md-9 {
            flex: 0 0 77%;
            max-width: 77%;
        }
        .col-md-2 {
            flex: 0 0 14.66667%;
            max-width: 14.66667%;
        }
    }
    @include screen($xxl) {
        .col-md-9 {
            flex: 0 0 82%;
            max-width: 82%;
        }
        .col-md-2 {
            flex: 0 0 9.66667%;
            max-width: 9.66667%;
        }
    }
}
div#table-tareas:hover{
    background-color: #f3f6f9f5;
}
div#cardList div:last-child{
    border-bottom: none!important;
}
div#cardList div:last-child{
    border-bottom: none!important;
}
.border-botton-2px{
    border-bottom: 1px solid #ebedf3;
}

.dataTable{
    table{
        width: 100%;
        .responsive-td{
            width: 1%;
            flex-wrap: nowrap;
            font-weight: 500;
            letter-spacing: 0.5px;
            white-space: nowrap;
            font-size: 0.9rem;
        }
        td{
            .justify-content-center{
                justify-content: left!important;
            }
            .text-center{
                text-align: left!important;
            }
        }
    }
}

.custom-td-descripcion{
    min-width: 400px;
    @include down-screen($md) {
        min-width: unset!important;
    }
}
    input#acta,
    input#curp,
    input#rfc,
    input#nss,
    input#identificacion,
    input#domicilio,
    input#estudios,
    input#bancaria,
    input#retencion,
    input#firma,
    input#foto,
    input#imss,
    input#bajaimss,
    input#responsiva
    {
        display: none;
    }