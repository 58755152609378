.btn-primary {
    color: $bone;
    background-color: $dark-blue;
    border-color: $dark-blue;

    &:hover {
        background-color: $gold-80;
        border-color: transparent;
        color: $dark-blue;
    }

    &:active {
        background-color: $gold-80;
        border-color: transparent;
        color: $dark-blue;
    }
}

.button {
    &__green {
        background-color: green;
        border-color: green;
        color: $bone;

        &:hover {
            background-color: transparent;
            border-color: green;
            color: green;
        }

        &:active {
            background-color: transparent;
            border-color: green;
            color: green;
        }
    }

    &__red {
        background-color: transparent;
        border-color: transparent;
        color: red;

        &:hover {
            background-color: transparent;
            border-color: transparent;
            color: red;
            text-decoration: underline;
        }

        &:active {
            background-color: transparent;
            border-color: transparent;
            text-decoration: underline;
        }
    }

    &__transparent {
        background-color: transparent !important;
        border: 0px !important;
        color: $blue !important;

        &:hover {
            background-color: transparent !important;
            border: 0px !important;
            color: $blue !important;
        }
    }

    &__l-blue {
        background-color: $l-blue !important;
        border: 0px !important;
        color: $gold !important;

        &:hover {
            background-color: $gold !important;
            border: 0px !important;
            color: $l-blue !important;
        }
    }

    &__gold-no-bg {
        background-color: transparent !important;
        border: 0px !important;
        color: $gold !important;

        &:hover {
            background-color: transparent !important;
            border: 0px !important;
            color: $dark-blue !important;
        }
    }

    &__blue {
        background-color: $blue !important;
        border: 0px !important;
        color: $bone !important;

        &:hover {
            background-color: $bone !important;
            border: 0px !important;
            color: $blue !important;
        }
    }
}

.small-button {
    max-width: 30px;
    max-height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.delete-item {
    border-radius: 50%;
    right: 0rem;
    top: 0rem;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding: 0;
    color: black;
    background-color: transparent;
    border-color: transparent;
    transition: all 0.5s;

    &:hover {
        padding: 0;
        color: black;
        background-color: transparent;
        border-color: transparent;
        width: 35px !important;
        height: 35px !important;
        font-size: 35px !important;
    }
}

.icon {
    width: 50px;
    height: 50px;
    padding: 0;
    font-size: 40px;
}

.btn {
    &.disabled {
        cursor: not-allowed;
    }

    &.btn-bg-blue {
        background-color: $blue-plataforma;
        border-color: $blue-plataforma;
    }

    &.btn-text-blue {
        color: $blue-plataforma;

        &.svg-icon svg &:hover {
            g [fill] {
                -webkit-transition: fill 0.3s ease;
                transition: fill 0.3s ease;
            }
        }

        g [fill] {
            -webkit-transition: fill 0.3s ease;
            transition: fill 0.3s ease;
            fill: $blue-plataforma;
        }
    }
}

.download-item {
    height: 20vh;
}

.float-button {
    top: 0rem;
    bottom: 0rem;
    right: 3rem;
    z-index: 10;
}

// btn.btn-light-pink 
.btn.btn-light-pink {
    color: #e44e93;
    background-color: #fbeff7;
    border-color: transparent;
}

.btn.btn-light-pink i {
    color: #e44e93;
}

.btn.btn-light-pink .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93;
}

.btn.btn-light-pink .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink.dropdown-toggle:after {
    color: #e44e93;
}

.btn.btn-light-pink:hover:not(.btn-text),
.btn.btn-light-pink:focus:not(.btn-text),
.btn.btn-light-pink.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #e44e93;
    border-color: transparent;
}

.btn.btn-light-pink:hover:not(.btn-text) i,
.btn.btn-light-pink:focus:not(.btn-text) i,
.btn.btn-light-pink.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-pink:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pink:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pink.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-pink:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pink:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pink.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pink:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pink.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-pink.disabled,
.btn.btn-light-pink:disabled {
    color: #e44e93;
    background-color: #fbeff7;
    border-color: transparent;
}

.btn.btn-light-pink.disabled i,
.btn.btn-light-pink:disabled i {
    color: #e44e93;
}

.btn.btn-light-pink.disabled .svg-icon svg g [fill],
.btn.btn-light-pink:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93;
}

.btn.btn-light-pink.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-pink:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink.disabled.dropdown-toggle:after,
.btn.btn-light-pink:disabled.dropdown-toggle:after {
    color: #e44e93;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-pink:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-pink.dropdown-toggle,
.show .btn.btn-light-pink.btn-dropdown {
    color: #ffffff;
    background-color: #e44e93;
    border-color: transparent;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-pink:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-pink.dropdown-toggle i,
.show .btn.btn-light-pink.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pink:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-pink.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-pink.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pink:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-pink.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-pink.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pink:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-pink.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-pink.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-pink:hover:not(.btn-text),
.btn.btn-hover-text-pink:focus:not(.btn-text),
.btn.btn-hover-text-pink.focus:not(.btn-text) {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:hover:not(.btn-text) i,
.btn.btn-hover-text-pink:focus:not(.btn-text) i,
.btn.btn-hover-text-pink.focus:not(.btn-text) i {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-pink:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-pink.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93 !important;
}

.btn.btn-hover-text-pink:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-pink:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-pink.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-pink:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-pink:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-pink.focus:not(.btn-text).dropdown-toggle:after {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-pink.dropdown-toggle,
.show .btn.btn-hover-text-pink.btn-dropdown {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-pink.dropdown-toggle i,
.show .btn.btn-hover-text-pink.btn-dropdown i {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-pink.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-pink.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-pink.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-pink.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-pink.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-pink.btn-dropdown.dropdown-toggle:after {
    color: #e44e93 !important;
}

.bg-hover-pink {
    cursor: pointer
}

.bg-hover-pink:hover {
    background-color: #FDEDF7 !important;
    border-color: #FDEDF7 !important;
}

// btn.btn-light-brown 
.btn.btn-light-brown {
    color: #5D4037;
    background-color: #EFEBE9;
    border-color: transparent;
}

.btn.btn-light-brown i {
    color: #5D4037;
}

.btn.btn-light-brown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5D4037;
}

.btn.btn-light-brown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-brown.dropdown-toggle:after {
    color: #5D4037;
}

.btn.btn-light-brown:hover:not(.btn-text),
.btn.btn-light-brown:focus:not(.btn-text),
.btn.btn-light-brown.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #5D4037;
    border-color: transparent;
}

.btn.btn-light-brown:hover:not(.btn-text) i,
.btn.btn-light-brown:focus:not(.btn-text) i,
.btn.btn-light-brown.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-brown:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-brown:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-brown.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-brown:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-brown:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-brown.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-brown:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-brown:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-brown.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-brown.disabled,
.btn.btn-light-brown:disabled {
    color: #5D4037;
    background-color: #EEE5FF;
    border-color: transparent;
}

.btn.btn-light-brown.disabled i,
.btn.btn-light-brown:disabled i {
    color: #5D4037;
}

.btn.btn-light-brown.disabled .svg-icon svg g [fill],
.btn.btn-light-brown:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5D4037;
}

.btn.btn-light-brown.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-brown:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-brown.disabled.dropdown-toggle:after,
.btn.btn-light-brown:disabled.dropdown-toggle:after {
    color: #5D4037;
}

.btn.btn-light-brown:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-brown:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-brown.dropdown-toggle,
.show .btn.btn-light-brown.btn-dropdown {
    color: #ffffff;
    background-color: #5D4037;
    border-color: transparent;
}

.btn.btn-light-brown:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-brown:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-brown.dropdown-toggle i,
.show .btn.btn-light-brown.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-brown:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-brown:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-brown.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-brown.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-brown:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-brown:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-brown.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-brown.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-brown:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-brown:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-brown.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-brown.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-brown:hover:not(.btn-text),
.btn.btn-hover-text-brown:focus:not(.btn-text),
.btn.btn-hover-text-brown.focus:not(.btn-text) {
    color: #5D4037 !important;
}

.btn.btn-hover-text-brown:hover:not(.btn-text) i,
.btn.btn-hover-text-brown:focus:not(.btn-text) i,
.btn.btn-hover-text-brown.focus:not(.btn-text) i {
    color: #5D4037 !important;
}

.btn.btn-hover-text-brown:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-brown:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-brown.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5D4037 !important;
}

.btn.btn-hover-text-brown:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-brown:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-brown.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-brown:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-brown:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-brown.focus:not(.btn-text).dropdown-toggle:after {
    color: #5D4037 !important;
}

.btn.btn-hover-text-brown:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-brown:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-brown.dropdown-toggle,
.show .btn.btn-hover-text-brown.btn-dropdown {
    color: #5D4037 !important;
}

.btn.btn-hover-text-brown:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-brown:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-brown.dropdown-toggle i,
.show .btn.btn-hover-text-brown.btn-dropdown i {
    color: #5D4037 !important;
}

.btn.btn-hover-text-brown:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-brown:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-brown.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-brown.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5D4037 !important;
}

.btn.btn-hover-text-brown:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-brown:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-brown.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-brown.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-brown:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-brown:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-brown.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-brown.btn-dropdown.dropdown-toggle:after {
    color: #5D4037 !important;
}

// btn.btn-light-gray 
.btn.btn-light-gray {
    color: #5F6A6A;
    background-color: #ECEFF1;
    border-color: transparent;
}

.btn.btn-light-gray i {
    color: #5F6A6A;
}

.btn.btn-light-gray .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A;
}

.btn.btn-light-gray .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray.dropdown-toggle:after {
    color: #5F6A6A;
}

.btn.btn-light-gray:hover:not(.btn-text),
.btn.btn-light-gray:focus:not(.btn-text),
.btn.btn-light-gray.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #5F6A6A;
    border-color: transparent;
}

.btn.btn-light-gray:hover:not(.btn-text) i,
.btn.btn-light-gray:focus:not(.btn-text) i,
.btn.btn-light-gray.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-gray:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-gray:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-gray.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-gray:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-gray:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-gray.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-gray:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-gray.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-gray.disabled,
.btn.btn-light-gray:disabled {
    color: #5F6A6A;
    background-color: #EEE5FF;
    border-color: transparent;
}

.btn.btn-light-gray.disabled i,
.btn.btn-light-gray:disabled i {
    color: #5F6A6A;
}

.btn.btn-light-gray.disabled .svg-icon svg g [fill],
.btn.btn-light-gray:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A;
}

.btn.btn-light-gray.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-gray:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray.disabled.dropdown-toggle:after,
.btn.btn-light-gray:disabled.dropdown-toggle:after {
    color: #5F6A6A;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-gray:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-gray.dropdown-toggle,
.show .btn.btn-light-gray.btn-dropdown {
    color: #ffffff;
    background-color: #5F6A6A;
    border-color: transparent;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-gray:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-gray.dropdown-toggle i,
.show .btn.btn-light-gray.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-gray:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-gray.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-gray.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-gray:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-gray.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-gray.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-gray:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-gray.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-gray.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-gray:hover:not(.btn-text),
.btn.btn-hover-text-gray:focus:not(.btn-text),
.btn.btn-hover-text-gray.focus:not(.btn-text) {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:hover:not(.btn-text) i,
.btn.btn-hover-text-gray:focus:not(.btn-text) i,
.btn.btn-hover-text-gray.focus:not(.btn-text) i {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-gray:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-gray.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A !important;
}

.btn.btn-hover-text-gray:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-gray:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-gray.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-gray:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-gray:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-gray.focus:not(.btn-text).dropdown-toggle:after {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-gray.dropdown-toggle,
.show .btn.btn-hover-text-gray.btn-dropdown {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-gray.dropdown-toggle i,
.show .btn.btn-hover-text-gray.btn-dropdown i {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-gray.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-gray.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-gray.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-gray.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-gray.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-gray.btn-dropdown.dropdown-toggle:after {
    color: #5F6A6A !important;
}

.button-up {
    z-index: 1;
    right: 8px;
    top: 5px;

    &:hover {
        cursor: pointer;
    }

    i {
        font-size: 20px;
    }
}

.btn.btn-light-pinterest {
    color: #e60023;
    background-color: #FFEBEE;
    border-color: transparent;
}

.btn.btn-light-pinterest i {
    color: #e60023;
}

.btn.btn-light-pinterest .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e60023;
}

.btn.btn-light-pinterest .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pinterest.dropdown-toggle:after {
    color: #e60023;
}

.btn.btn-light-pinterest:hover:not(.btn-text),
.btn.btn-light-pinterest:focus:not(.btn-text),
.btn.btn-light-pinterest.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #e60023;
    border-color: transparent;
}

.btn.btn-light-pinterest:hover:not(.btn-text) i,
.btn.btn-light-pinterest:focus:not(.btn-text) i,
.btn.btn-light-pinterest.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-pinterest:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pinterest:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pinterest.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-pinterest:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pinterest:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pinterest.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pinterest:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pinterest:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pinterest.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-pinterest.disabled,
.btn.btn-light-pinterest:disabled {
    color: #e60023;
    background-color: #FFEBEE;
    border-color: transparent;
}

.btn.btn-light-pinterest.disabled i,
.btn.btn-light-pinterest:disabled i {
    color: #e60023;
}

.btn.btn-light-pinterest.disabled .svg-icon svg g [fill],
.btn.btn-light-pinterest:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e60023;
}

.btn.btn-light-pinterest.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-pinterest:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pinterest.disabled.dropdown-toggle:after,
.btn.btn-light-pinterest:disabled.dropdown-toggle:after {
    color: #e60023;
}

.btn.btn-light-pinterest:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-pinterest:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-pinterest.dropdown-toggle,
.show .btn.btn-light-pinterest.btn-dropdown {
    color: #ffffff;
    background-color: #e60023;
    border-color: transparent;
}

.btn.btn-light-pinterest:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-pinterest:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-pinterest.dropdown-toggle i,
.show .btn.btn-light-pinterest.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-pinterest:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pinterest:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-pinterest.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-pinterest.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-pinterest:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pinterest:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-pinterest.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-pinterest.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pinterest:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pinterest:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-pinterest.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-pinterest.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}


// btn.btn-light-inein 
.btn.btn-light-inein {
    color: #D8005A;
    background-color: #ffebf3;
    border-color: transparent;
}

.btn.btn-light-inein i {
    color: #D8005A;
}

.btn.btn-light-inein .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D8005A;
}

.btn.btn-light-inein .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-inein.dropdown-toggle:after {
    color: #D8005A;
}

.btn.btn-light-inein:hover:not(.btn-text),
.btn.btn-light-inein:focus:not(.btn-text),
.btn.btn-light-inein.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #D8005A;
    border-color: transparent;
}

.btn.btn-light-inein:hover:not(.btn-text) i,
.btn.btn-light-inein:focus:not(.btn-text) i,
.btn.btn-light-inein.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-inein:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-inein:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-inein.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-inein:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-inein:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-inein.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-inein:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-inein:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-inein.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-inein.disabled,
.btn.btn-light-inein:disabled {
    color: #D8005A;
    background-color: #EEE5FF;
    border-color: transparent;
}

.btn.btn-light-inein.disabled i,
.btn.btn-light-inein:disabled i {
    color: #D8005A;
}

.btn.btn-light-inein.disabled .svg-icon svg g [fill],
.btn.btn-light-inein:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D8005A;
}

.btn.btn-light-inein.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-inein:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-inein.disabled.dropdown-toggle:after,
.btn.btn-light-inein:disabled.dropdown-toggle:after {
    color: #D8005A;
}

.btn.btn-light-inein:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-inein:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-inein.dropdown-toggle,
.show .btn.btn-light-inein.btn-dropdown {
    color: #ffffff;
    background-color: #D8005A;
    border-color: transparent;
}

.btn.btn-light-inein:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-inein:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-inein.dropdown-toggle i,
.show .btn.btn-light-inein.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-inein:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-inein:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-inein.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-inein.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-inein:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-inein:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-inein.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-inein.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-inein:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-inein:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-inein.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-inein.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-inein:hover:not(.btn-text),
.btn.btn-hover-text-inein:focus:not(.btn-text),
.btn.btn-hover-text-inein.focus:not(.btn-text) {
    color: #D8005A !important;
}

.btn.btn-hover-text-inein:hover:not(.btn-text) i,
.btn.btn-hover-text-inein:focus:not(.btn-text) i,
.btn.btn-hover-text-inein.focus:not(.btn-text) i {
    color: #D8005A !important;
}

.btn.btn-hover-text-inein:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-inein:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-inein.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D8005A !important;
}

.btn.btn-hover-text-inein:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-inein:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-inein.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-inein:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-inein:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-inein.focus:not(.btn-text).dropdown-toggle:after {
    color: #D8005A !important;
}

.btn.btn-hover-text-inein:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-inein:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-inein.dropdown-toggle,
.show .btn.btn-hover-text-inein.btn-dropdown {
    color: #D8005A !important;
}

.btn.btn-hover-text-inein:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-inein:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-inein.dropdown-toggle i,
.show .btn.btn-hover-text-inein.btn-dropdown i {
    color: #D8005A !important;
}

.btn.btn-hover-text-inein:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-inein:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-inein.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-inein.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D8005A !important;
}

.btn.btn-hover-text-inein:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-inein:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-inein.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-inein.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-inein:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-inein:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-inein.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-inein.btn-dropdown.dropdown-toggle:after {
    color: #D8005A !important;
}

.btn-30px {
    height: 30px !important;
    width: 30px !important;
}

// btn.btn-light-im 
.btn.btn-light-im {
    color: #7096c1;
    background-color: #DDECFD;
    border-color: transparent;
}

.btn.btn-light-im i {
    color: #7096c1;
}

.btn.btn-light-im .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7096c1;
}

.btn.btn-light-im .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-im.dropdown-toggle:after {
    color: #7096c1;
}

.btn.btn-light-im:hover:not(.btn-text),
.btn.btn-light-im:focus:not(.btn-text),
.btn.btn-light-im.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #7096c1;
    border-color: transparent;
}

.btn.btn-light-im:hover:not(.btn-text) i,
.btn.btn-light-im:focus:not(.btn-text) i,
.btn.btn-light-im.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-im:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-im:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-im.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-im:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-im:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-im.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-im:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-im:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-im.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-im.disabled,
.btn.btn-light-im:disabled {
    color: #7096c1;
    background-color: #EEE5FF;
    border-color: transparent;
}

.btn.btn-light-im.disabled i,
.btn.btn-light-im:disabled i {
    color: #7096c1;
}

.btn.btn-light-im.disabled .svg-icon svg g [fill],
.btn.btn-light-im:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7096c1;
}

.btn.btn-light-im.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-im:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-im.disabled.dropdown-toggle:after,
.btn.btn-light-im:disabled.dropdown-toggle:after {
    color: #7096c1;
}

.btn.btn-light-im:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-im:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-im.dropdown-toggle,
.show .btn.btn-light-im.btn-dropdown {
    color: #ffffff;
    background-color: #7096c1;
    border-color: transparent;
}

.btn.btn-light-im:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-im:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-im.dropdown-toggle i,
.show .btn.btn-light-im.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-im:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-im:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-im.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-im.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-im:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-im:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-im.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-im.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-im:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-im:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-im.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-im.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-im:hover:not(.btn-text),
.btn.btn-hover-text-im:focus:not(.btn-text),
.btn.btn-hover-text-im.focus:not(.btn-text) {
    color: #7096c1 !important;
}

.btn.btn-hover-text-im:hover:not(.btn-text) i,
.btn.btn-hover-text-im:focus:not(.btn-text) i,
.btn.btn-hover-text-im.focus:not(.btn-text) i {
    color: #7096c1 !important;
}

.btn.btn-hover-text-im:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-im:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-im.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7096c1 !important;
}

.btn.btn-hover-text-im:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-im:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-im.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-im:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-im:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-im.focus:not(.btn-text).dropdown-toggle:after {
    color: #7096c1 !important;
}

.btn.btn-hover-text-im:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-im:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-im.dropdown-toggle,
.show .btn.btn-hover-text-im.btn-dropdown {
    color: #7096c1 !important;
}

.btn.btn-hover-text-im:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-im:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-im.dropdown-toggle i,
.show .btn.btn-hover-text-im.btn-dropdown i {
    color: #7096c1 !important;
}

.btn.btn-hover-text-im:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-im:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-im.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-im.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7096c1 !important;
}

.btn.btn-hover-text-im:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-im:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-im.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-im.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-im:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-im:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-im.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-im.btn-dropdown.dropdown-toggle:after {
    color: #7096c1 !important;
}

.btn.btn-light-im.btn-shadow-hover:hover:not(.btn-text),
.btn.btn-light-im.btn-shadow-hover:focus:not(.btn-text),
.btn.btn-light-im.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-light-im.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-im.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-im.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

// BOTÓN DE LOGIN
//colors
$white: #fff;

.container-login {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-login {
    cursor: pointer;
    font-size: 16px;
    line-height: 45px;
    max-width: 160px;
    position: relative;
    width: 100%;

    &:hover {
        text-decoration: none;
        font-weight: 400 !important;
    }
}

.btn-1 {
    svg {
        height: 45px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    rect {
        fill: none;
        stroke: #7fa1c9;
        stroke-width: 2;
        stroke-dasharray: 422, 0;
        transition: all 0.35s linear;
    }
}

.btn-1:hover {
    font-weight: 900 !important;
    letter-spacing: 1px;

    rect {
        stroke-width: 5;
        stroke-dasharray: 15, 310;
        stroke-dashoffset: 48;
        transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

.btn-xxs {
    height: 20px !important;
    width: 20px !important;
}


// btn.btn-light-orange 
.btn.btn-light-orange {
    color: #D08905;
    background-color: #EFEBE9;
    border-color: transparent;
}

.btn.btn-light-orange i {
    color: #D08905;
}

.btn.btn-light-orange .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905;
}

.btn.btn-light-orange .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange.dropdown-toggle:after {
    color: #D08905;
}

.btn.btn-light-orange:hover:not(.btn-text),
.btn.btn-light-orange:focus:not(.btn-text),
.btn.btn-light-orange.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #D08905;
    border-color: transparent;
}

.btn.btn-light-orange:hover:not(.btn-text) i,
.btn.btn-light-orange:focus:not(.btn-text) i,
.btn.btn-light-orange.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-orange:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-orange:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-orange.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-orange:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-orange:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-orange.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-orange:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-orange.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-orange.disabled,
.btn.btn-light-orange:disabled {
    color: #D08905;
    background-color: #EEE5FF;
    border-color: transparent;
}

.btn.btn-light-orange.disabled i,
.btn.btn-light-orange:disabled i {
    color: #D08905;
}

.btn.btn-light-orange.disabled .svg-icon svg g [fill],
.btn.btn-light-orange:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905;
}

.btn.btn-light-orange.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-orange:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange.disabled.dropdown-toggle:after,
.btn.btn-light-orange:disabled.dropdown-toggle:after {
    color: #D08905;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-orange:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-orange.dropdown-toggle,
.show .btn.btn-light-orange.btn-dropdown {
    color: #ffffff;
    background-color: #D08905;
    border-color: transparent;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-orange:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-orange.dropdown-toggle i,
.show .btn.btn-light-orange.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-orange:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-orange.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-orange.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-orange:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-orange.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-orange.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-orange:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-orange.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-orange.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-orange:hover:not(.btn-text),
.btn.btn-hover-text-orange:focus:not(.btn-text),
.btn.btn-hover-text-orange.focus:not(.btn-text) {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:hover:not(.btn-text) i,
.btn.btn-hover-text-orange:focus:not(.btn-text) i,
.btn.btn-hover-text-orange.focus:not(.btn-text) i {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-orange:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-orange.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905 !important;
}

.btn.btn-hover-text-orange:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-orange:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-orange.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-orange:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-orange:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-orange.focus:not(.btn-text).dropdown-toggle:after {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-orange.dropdown-toggle,
.show .btn.btn-hover-text-orange.btn-dropdown {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-orange.dropdown-toggle i,
.show .btn.btn-hover-text-orange.btn-dropdown i {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-orange.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-orange.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-orange.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-orange.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-orange.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-orange.btn-dropdown.dropdown-toggle:after {
    color: #D08905 !important;
}

//BTN ICON
.btn.btn-icon-primary i {
    color: #2171c1
}

.btn.btn-icon-primary .svg-icon svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #2171c1
}

.btn.btn-icon-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.btn.btn-icon-primary.dropdown-toggle:after {
    color: #2171c1
}

.btn.btn-icon-primary.disabled i,
.btn.btn-icon-primary:disabled i {
    color: #2171c1
}

.btn.btn-icon-primary.disabled .svg-icon svg g [fill],
.btn.btn-icon-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #2171c1
}

.btn.btn-icon-primary.disabled .svg-icon svg:hover g [fill],
.btn.btn-icon-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.btn.btn-icon-primary.disabled.dropdown-toggle:after,
.btn.btn-icon-primary:disabled.dropdown-toggle:after {
    color: #2171c1
}

//BTN HOVER ICON
.btn.btn-hover-icon-success.focus:not(.btn-text) i,
.btn.btn-hover-icon-success:focus:not(.btn-text) i,
.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled) i {
    color: #388E3C !important
}

.btn.btn-hover-icon-success.focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-icon-success:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #388E3C !important
}

.btn.btn-hover-icon-success.focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-icon-success:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.btn.btn-hover-icon-success.focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-icon-success:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after {
    color: #388E3C !important
}

.btn.btn-hover-icon-success:not(:disabled):not(.disabled).active i,
.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text) i,
.show .btn.btn-hover-icon-success.btn-dropdown i,
.show>.btn.btn-hover-icon-success.dropdown-toggle i {
    color: #388E3C !important
}

.btn.btn-hover-icon-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.show .btn.btn-hover-icon-success.btn-dropdown .svg-icon svg g [fill],
.show>.btn.btn-hover-icon-success.dropdown-toggle .svg-icon svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #388E3C !important
}

.btn.btn-hover-icon-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-success.btn-dropdown .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-icon-success.dropdown-toggle .svg-icon svg:hover g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.btn.btn-hover-icon-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.show .btn.btn-hover-icon-success.btn-dropdown.dropdown-toggle:after,
.show>.btn.btn-hover-icon-success.dropdown-toggle.dropdown-toggle:after {
    color: #388E3C !important
}

.btn.btn-hover-icon-danger.focus:not(.btn-text) i,
.btn.btn-hover-icon-danger:focus:not(.btn-text) i,
.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i {
    color: #f64e60 !important
}

.btn.btn-hover-icon-danger.focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-icon-danger:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #f64e60 !important
}

.btn.btn-hover-icon-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-icon-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.btn.btn-hover-icon-danger.focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-icon-danger:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after {
    color: #f64e60 !important
}

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active i,
.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text) i,
.show .btn.btn-hover-icon-danger.btn-dropdown i,
.show>.btn.btn-hover-icon-danger.dropdown-toggle i {
    color: #f64e60 !important
}

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.show .btn.btn-hover-icon-danger.btn-dropdown .svg-icon svg g [fill],
.show>.btn.btn-hover-icon-danger.dropdown-toggle .svg-icon svg g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease;
    fill: #f64e60 !important
}

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-danger.btn-dropdown .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-icon-danger.dropdown-toggle .svg-icon svg:hover g [fill] {
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.show .btn.btn-hover-icon-danger.btn-dropdown.dropdown-toggle:after,
.show>.btn.btn-hover-icon-danger.dropdown-toggle.dropdown-toggle:after {
    color: #f64e60 !important
}

.btn.btn-light-blog {
    color: #e8762a;
    background-color: #FFEEE3;
    border-color: transparent;
}

.btn.btn-light-blog i {
    color: #e8762a;
}

.btn.btn-light-blog:hover:not(.btn-text),
.btn.btn-light-blog:focus:not(.btn-text),
.btn.btn-light-blog.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #e8762a;
    border-color: transparent;
}

.btn.btn-light-blog:hover:not(.btn-text) i,
.btn.btn-light-blog:focus:not(.btn-text) i,
.btn.btn-light-blog.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn-historial-presupuestos {
    width: fit-content;
    font-size: 1rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    background: #f3f6f9;
    padding: 5px 5px;
    border-radius: 3px;
    color: #464E5F !important;
    cursor: pointer;

    u {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: #8950fc;
    }

    :hover {
        color: #8950fc !important;
        font-weight: 500;
    }
}

.btn.btn-flex {
    display: inline-flex;
    align-items: flex-end;
}