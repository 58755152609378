div#css-2b097c-container-gray{
    .css-xb97g8:hover{
        border-color: #f3f6f9!important; 
        color: #80808F!important;
        background-color: #d3d3d9!important;
    }
    .css-1hwfws3{
        padding:2px 0px!important;
        border-color:  #f3f6f9!important;
    }
}