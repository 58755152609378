.wizard.wizard-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.wizard.wizard-2 .wizard-nav {
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    width: 400px;
    max-width: 100%;
}

.wizard.wizard-2 .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step {
    padding: 0.75rem 1.5rem;
    position: relative;
    border-radius: 0.5rem;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon {
    font-size: 2.5rem;
    margin-right: 1.1rem;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon i {
    color: #B5B5C3;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    color: #212121;
    font-weight: 500;
    font-size: 1.1rem;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
    color: #80808F;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
    color: #80808F;
    font-size: 1.25rem;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
    display: none;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] {
    background-color: #F3F6F9;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon i {
    color: #3699FF !important;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699FF !important;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"]:after {
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: " ";
    height: 0;
    width: 0;
    border: solid transparent;
    position: absolute;
    border-left-color: #F3F6F9;
    border-width: 1rem;
}

.wizard.wizard-2 .wizard-body {
    width: 100%;
}

.wizard.wizard-2 [data-wizard-type="step-info"] {
    display: none;
}

.wizard.wizard-2 [data-wizard-type="step-info"][data-wizard-state="current"] {
    display: block;
}

.wizard.wizard-2 [data-wizard-type="step-content"] {
    display: none;
}

.wizard.wizard-2 [data-wizard-type="step-content"][data-wizard-state="current"] {
    display: block;
}

.wizard.wizard-2 [data-wizard-type="action-prev"] {
    display: none;
}

.wizard.wizard-2 [data-wizard-type="action-next"] {
    display: inline-block;
}

.wizard.wizard-2 [data-wizard-type="action-submit"] {
    display: none;
}

.wizard.wizard-2[data-wizard-state="first"] [data-wizard-type="action-prev"] {
    display: none;
}

.wizard.wizard-2[data-wizard-state="first"] [data-wizard-type="action-next"] {
    display: inline-block;
}

.wizard.wizard-2[data-wizard-state="first"] [data-wizard-type="action-submit"] {
    display: none;
}

.wizard.wizard-2[data-wizard-state="between"] [data-wizard-type="action-prev"] {
    display: inline-block;
}

.wizard.wizard-2[data-wizard-state="between"] [data-wizard-type="action-next"] {
    display: inline-block;
}

.wizard.wizard-2[data-wizard-state="between"] [data-wizard-type="action-submit"] {
    display: none;
}

.wizard.wizard-2[data-wizard-state="last"] [data-wizard-type="action-prev"] {
    display: inline-block;
}

.wizard.wizard-2[data-wizard-state="last"] [data-wizard-type="action-next"] {
    display: none;
}

.wizard.wizard-2[data-wizard-state="last"] [data-wizard-type="action-submit"] {
    display: inline-block;
}

.wizard.wizard-2[data-wizard-clickable="true"] .wizard-step {
    cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1399.98px) {
    .wizard.wizard-2 .wizard-nav {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 325px;
        flex: 0 0 325px;
        width: 325px;
    }
}

@media (max-width: 991.98px) {
    .wizard.wizard-2 {
        display: block;
    }

    .wizard.wizard-2 .wizard-nav {
        width: 100%;
        border-bottom: 1px solid #ECF0F3;
    }

    .wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"]:after {
        content: none;
    }

    .wizard.wizard-2 .wizard-body {
        width: 100%;
    }
}