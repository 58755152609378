@charset "UTF-8";

@media (min-width:992px) {
    .header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-transition: height .3s ease;
        transition: height .3s ease;
        position: relative;
        z-index: 2
    }

    .header .header-bottom {
        height: 170px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .header .header-bottom .container,
    .header .header-bottom .container-fluid,
    .header .header-bottom .container-lg,
    .header .header-bottom .container-md,
    .header .header-bottom .container-sm,
    .header .header-bottom .container-xl,
    .header .header-bottom .container-xxl {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .header-fixed[data-header-scroll=on] .header {
        position: fixed;
        z-index: 97;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .08);
        box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .08);
        height: 80px;
        -webkit-animation: header-scroll-animation .5s ease 1;
        animation: header-scroll-animation .5s ease 1
    }

    .header-fixed[data-header-scroll=on] .header .header-top {
        height: 80px;
        -webkit-animation: header-scroll-animation .5s ease 1;
        animation: header-scroll-animation .5s ease 1
    }

    .header-fixed[data-header-scroll=on] .header .header-bottom {
        display: none
    }
}

@media (max-width:991.98px) {

    .header .header-bottom .container,
    .header .header-bottom .container-fluid,
    .header .header-bottom .container-lg,
    .header .header-bottom .container-md,
    .header .header-bottom .container-sm,
    .header .header-bottom .container-xl,
    .header .header-bottom .container-xxl,
    .header .header-top .container,
    .header .header-top .container-fluid,
    .header .header-top .container-lg,
    .header .header-top .container-md,
    .header .header-top .container-sm,
    .header .header-top .container-xl,
    .header .header-top .container-xxl {
        padding: 0
    }
}

@-webkit-keyframes header-scroll-animation {
    from {
        top: -100px
    }

    to {
        top: 0
    }
}

@keyframes header-scroll-animation {
    from {
        top: -100px
    }

    to {
        top: 0
    }
}

@media (max-width:991.98px) {
    .header-bottom {
        z-index: 1001;
        position: fixed;
        top: 0;
        bottom: 0;
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        right: -295px;
        width: 275px
    }

    .header-bottom.header-bottom-on {
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        right: 0
    }
}

@media screen and (max-width:991.98px) and (-ms-high-contrast:active),
(max-width:991.98px) and (-ms-high-contrast:none) {
    .header-bottom {
        -webkit-transition: none !important;
        transition: none !important
    }
}

@media (max-width:991.98px) {
    .header-bottom.header-bottom-right {
        left: -295px;
        right: auto
    }

    .header-bottom.header-bottom-right.header-bottom-on {
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        left: 0;
        right: auto
    }
}

@media (max-width:991.98px) {
    .header-bottom-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        z-index: 1000;
        -webkit-animation: animation-offcanvas-fade-in .6s ease 1;
        animation: animation-offcanvas-fade-in .6s ease 1
    }
}

@media (max-width:991.98px) {
    @-webkit-keyframes animation-offcanvas-fade-in {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }

    @keyframes animation-offcanvas-fade-in {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }
}

@media (max-width:991.98px) {
    .header-bottom {
        background: #fff;
        -webkit-box-shadow: 0 1px 9px -3px rgba(0, 0, 0, .1);
        box-shadow: 0 1px 9px -3px rgba(0, 0, 0, .1)
    }
}

@media (max-width:991.98px) {
    .header-bottom-overlay {
        background: rgba(0, 0, 0, .1)
    }
}

@media (max-width:325px) {
    .header-bottom {
        width: 90% !important
    }
}

@media (max-width:991.98px) {
    [data-offcanvas-header-bottom=on] {
        overflow: hidden !important
    }
}

@media (min-width:992px) {
    .header-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        height: 100%;
        margin: 0
    }

    .header-menu .menu-nav {
        list-style: none;
        margin: 0;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .header-menu .menu-nav>.menu-item .menu-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-decoration: none;
        position: relative;
        vertical-align: middle;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        outline: 0 !important;
        text-decoration: none;
        cursor: pointer
    }

    .header-menu .menu-nav>.menu-item .menu-link .menu-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        padding: 0
    }

    .header-menu .menu-nav>.menu-item .menu-link .menu-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0;
        white-space: nowrap
    }

    .header-menu .menu-nav>.menu-item .menu-link .menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .header-menu .menu-nav>.menu-item .menu-link .menu-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .header-menu .menu-nav>.menu-item .menu-link:active,
    .header-menu .menu-nav>.menu-item .menu-link:focus,
    .header-menu .menu-nav>.menu-item .menu-link:hover {
        text-decoration: none
    }

    .header-menu .menu-nav>.menu-item>.menu-link>.menu-text {
        width: auto
    }

    .header-menu .menu-nav>.menu-item.menu-item-icon>.menu-link {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .header-menu .menu-nav>.menu-item.menu-item-icon>.menu-link>.menu-icon {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .header-menu .menu-nav>.menu-item .menu-submenu {
        display: none;
        z-index: 98;
        position: absolute;
        top: 100%;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        border-radius: 4px;
        padding: 20px 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-scroll {
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-subnav {
        list-style: none !important;
        padding: 0;
        margin: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu {
        width: 275px;
        margin: 0 auto;
        right: auto;
        left: auto
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-full {
        margin: 0 auto;
        width: auto;
        right: 20px;
        left: 20px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed {
        right: auto;
        left: auto;
        width: auto
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-right {
        left: 0;
        right: auto
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-right.menu-submenu-pull {
        margin-left: -40px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-left {
        left: auto;
        right: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-left.menu-submenu-pull {
        margin-right: -40px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-center {
        margin: 0 auto;
        right: 0;
        left: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-submenu {
        position: relative;
        padding: 0;
        margin: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-submenu>.menu-submenu {
        top: 0;
        display: none;
        margin-top: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-submenu>.menu-submenu.menu-submenu-left {
        left: 100%;
        right: auto
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-submenu>.menu-submenu.menu-submenu-right {
        right: 100%;
        left: auto
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-submenu.menu-item-dropup>.menu-submenu {
        top: auto;
        bottom: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-content>.menu-item {
        padding: 0;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-content>.menu-item:last-child {
        border-left: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-heading {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0;
        margin: 0;
        text-decoration: none;
        position: relative
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-heading .menu-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-heading .menu-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        white-space: nowrap
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-heading .menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 18px;
        padding: 0 0 0 10px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-heading .menu-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 10px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-inner,
    .header-menu .menu-nav>.menu-item .menu-subnav {
        list-style: none;
        margin: 0;
        padding: 0
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin: 0;
        padding: 10px 20px
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        text-decoration: none;
        position: relative
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link:active,
    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link:focus,
    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link:hover,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link:active,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link:focus,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link:hover {
        outline: 0;
        text-decoration: none
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link .menu-text,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link .menu-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-weight: 400;
        font-size: 1rem;
        text-transform: initial
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link .menu-label,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link .menu-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        white-space: nowrap;
        padding: 0 5px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link .menu-icon,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link .menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33px;
        flex: 0 0 33px;
        padding: 0;
        font-size: 1.4rem
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link .menu-bullet,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link .menu-bullet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0
    }

    .header-menu .menu-nav>.menu-item .menu-inner>.menu-item .menu-link .menu-arrow,
    .header-menu .menu-nav>.menu-item .menu-subnav>.menu-item .menu-link .menu-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 10px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-inner {
        padding: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-submenu {
        margin-right: 1px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu .menu-submenu.menu-submenu-left {
        margin-left: 1px
    }

    .header-menu .menu-nav>.menu-item.menu-item-submenu.menu-item-tabs>.menu-submenu>.menu-subnav>.menu-item.menu-item-submenu>.menu-submenu {
        top: 100%
    }

    .header-menu .menu-nav>.menu-item.menu-item-submenu.menu-item-tabs>.menu-submenu>.menu-subnav>.menu-item.menu-item-submenu>.menu-submenu.menu-submenu-left {
        left: 100%;
        right: 0
    }

    .header-menu .menu-nav>.menu-item.menu-item-submenu.menu-item-tabs>.menu-submenu>.menu-subnav>.menu-item.menu-item-submenu>.menu-submenu.menu-submenu-right {
        right: 100%;
        left: 0
    }

    .header-menu .menu-nav>.menu-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 .25rem;
        padding: 0 .25rem
    }

    .header-menu .menu-nav>.menu-item>.menu-link>.menu-arrow {
        display: none
    }

    .header-menu .menu-nav>.menu-item>.menu-link>.menu-arrow:before {
        content: "" !important
    }

    .header-menu .menu-nav>.menu-item.menu-item-rel {
        position: relative
    }

    .header-menu .menu-nav>.menu-item>.menu-link {
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        cursor: pointer;
        padding: .25rem .5rem
    }

    .header-menu .menu-nav>.menu-item>.menu-link:hover {
        -webkit-transition: all .3s ease;
        transition: all .3s ease
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-icon {
        font-size: 1.4rem;
        width: 30px;
        padding: 0;
        line-height: 0
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-icon.svg-icon {
        height: 23px;
        width: 23px;
        margin-right: -2px
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-bullet {
        width: 20px
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-bullet>span {
        vertical-align: middle;
        display: inline-block
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        width: 4px;
        height: 4px;
        border-radius: 100%
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        width: 5px;
        height: 1px
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-text {
        font-weight: 400;
        font-size: 1rem;
        text-transform: initial
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-label {
        padding: 0 5px 0 0
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-arrow {
        font-size: .6rem;
        width: 20px;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-arrow:before {
        font-family: Ki;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: ""
    }

    [direction=rtl] .header-menu .menu-nav>.menu-item>.menu-link .menu-arrow:before {
        content: "" !important
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-item-here {
        display: none
    }

    .header-menu .menu-nav>.menu-item .menu-submenu {
        padding: 0;
        border-radius: 4px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item {
        padding: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link {
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        cursor: pointer;
        padding: 11px 30px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link:hover {
        -webkit-transition: all .3s ease;
        transition: all .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-icon {
        font-size: 1.4rem;
        width: 33px;
        padding: 0;
        line-height: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-icon.svg-icon {
        height: 23px;
        width: 23px;
        margin-right: -2px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-bullet {
        width: 20px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-bullet>span {
        vertical-align: middle;
        display: inline-block
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        width: 4px;
        height: 4px;
        border-radius: 100%
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        width: 9px;
        height: 1px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-text {
        font-weight: 400;
        font-size: 1rem;
        text-transform: initial
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-label {
        padding: 0 5px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-arrow {
        font-size: .6rem;
        width: 20px;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 10px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-arrow:before {
        font-family: Ki;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: ""
    }

    [direction=rtl] .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-arrow:before {
        content: "" !important
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-item-here {
        display: none
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content>.menu-item {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content>.menu-item:last-child {
        border-left: 0 !important
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-heading {
        padding: 30px 30px 10px 30px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-heading>.menu-text {
        font-weight: 500;
        font-size: 1.07rem;
        text-transform: initial
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-heading>.menu-icon {
        font-size: 1.35rem;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 30px;
        flex: 0 0 30px;
        padding: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-heading .menu-label {
        padding: 0 5px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner {
        padding: 0 0 20px 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item {
        padding: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link {
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        cursor: pointer;
        padding: 11px 30px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link:hover {
        -webkit-transition: all .3s ease;
        transition: all .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-icon {
        font-size: 1.4rem;
        width: 33px;
        padding: 0;
        line-height: 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-icon.svg-icon {
        height: 23px;
        width: 23px;
        margin-right: -2px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-bullet {
        width: 20px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-bullet>span {
        vertical-align: middle;
        display: inline-block
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        width: 4px;
        height: 4px;
        border-radius: 100%
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        width: 9px;
        height: 1px
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-text {
        font-weight: 400;
        font-size: 1rem;
        text-transform: initial
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-label {
        padding: 0 5px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-arrow {
        font-size: .6rem;
        width: 20px;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 10px 0 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-arrow:before {
        font-family: Ki;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: ""
    }

    [direction=rtl] .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-arrow:before {
        content: "" !important
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-item-here {
        display: none
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-classic {
        padding: 20px 0
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-inline {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-inline>.menu-subnav>.menu-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 5px 0
    }

    .header-menu .menu-nav .menu-item.menu-item-resize {
        display: none
    }

    .header-menu .menu-nav .menu-item.menu-item-active-tab .menu-submenu,
    .header-menu .menu-nav .menu-item.menu-item-hover .menu-submenu {
        display: block;
        -webkit-animation: header-menu-submenu-fade-in .3s ease 1, header-menu-submenu-move-down .3s ease-out 1;
        animation: header-menu-submenu-fade-in .3s ease 1, header-menu-submenu-move-down .3s ease-out 1
    }
}

@media screen and (min-width:992px) and (-ms-high-contrast:active),
(min-width:992px) and (-ms-high-contrast:none) {

    .header-menu .menu-nav .menu-item.menu-item-active-tab .menu-submenu,
    .header-menu .menu-nav .menu-item.menu-item-hover .menu-submenu {
        -webkit-animation: none;
        animation: none
    }
}

@media (min-width:992px) {

    .header-menu .menu-nav .menu-item.menu-item-active-tab .menu-submenu>.menu-subnav>.menu-item.menu-item-hover>.menu-submenu,
    .header-menu .menu-nav .menu-item.menu-item-hover .menu-submenu>.menu-subnav>.menu-item.menu-item-hover>.menu-submenu {
        display: block;
        -webkit-animation: header-menu-submenu-fade-in .3s ease 1, header-menu-submenu-move-up .3s ease-out 1;
        animation: header-menu-submenu-fade-in .3s ease 1, header-menu-submenu-move-up .3s ease-out 1
    }
}

@media screen and (min-width:992px) and (-ms-high-contrast:active),
(min-width:992px) and (-ms-high-contrast:none) {

    .header-menu .menu-nav .menu-item.menu-item-active-tab .menu-submenu>.menu-subnav>.menu-item.menu-item-hover>.menu-submenu,
    .header-menu .menu-nav .menu-item.menu-item-hover .menu-submenu>.menu-subnav>.menu-item.menu-item-hover>.menu-submenu {
        -webkit-animation: none;
        animation: none
    }
}

@media (min-width:992px) {
    .header-menu.header-menu-root-arrow .menu-nav>.menu-item>.menu-link>.menu-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (min-width:992px) and (max-width:1399.98px) {

    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-auto,
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-auto.menu-submenu-mimimal-desktop-wide,
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed,
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-xl,
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-xxl,
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-full {
        margin: 0 auto;
        width: auto;
        right: 20px;
        left: 20px
    }
}

@media (min-width:0) {
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-xs {
        width: -60px
    }
}

@media (min-width:576px) {
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-sm {
        width: 516px
    }
}

@media (min-width:768px) {
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-md {
        width: 708px
    }
}

@media (min-width:992px) {
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-lg {
        width: 932px
    }
}

@media (min-width:1200px) {
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-xl {
        width: 1140px
    }
}

@media (min-width:1400px) {
    .header-menu .menu-nav>.menu-item .menu-submenu.menu-submenu-fixed-xxl {
        width: 1340px
    }
}

@-webkit-keyframes header-menu-submenu-fade-out {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes header-menu-submenu-fade-out {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes header-menu-submenu-fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes header-menu-submenu-fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes header-menu-submenu-move-up {
    from {
        margin-top: -10px
    }

    to {
        margin-top: 0
    }
}

@keyframes header-menu-submenu-move-up {
    from {
        margin-top: -10px
    }

    to {
        margin-top: 0
    }
}

@-webkit-keyframes header-menu-submenu-move-down {
    from {
        margin-top: 10px
    }

    to {
        margin-top: 0
    }
}

@keyframes header-menu-submenu-move-down {
    from {
        margin-top: 10px
    }

    to {
        margin-top: 0
    }
}

@media (min-width:992px) {
    .header-menu .menu-nav>.menu-item>.menu-link {
        background-color: none
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-text {
        color: #3f4254
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-arrow {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link {
        background-color: none
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link {
        background-color: none
    }

    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link {
        background-color: none
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg g [fill],
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-dot>span,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-line>span,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link>.menu-arrow,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu {
        background-color: #fff;
        -webkit-box-shadow: 0 15px 50px 0 rgba(82, 63, 105, .15);
        box-shadow: 0 15px 50px 0 rgba(82, 63, 105, .15)
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-icon {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-text {
        color: #3f4254
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item>.menu-link .menu-arrow {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-active>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-here>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg g [fill],
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-dot>span,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-line>span,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link>.menu-arrow,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content>.menu-item {
        border-left: 1px solid #ebedf3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-heading>.menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-heading>.menu-icon {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-icon {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-text {
        color: #3f4254
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item>.menu-link .menu-arrow {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-active>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-here>.menu-link>.menu-arrow {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg g [fill],
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-dot>span,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-line>span,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link>.menu-arrow,
    .header-menu .menu-nav>.menu-item .menu-submenu>.menu-subnav .menu-content .menu-inner>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link>.menu-arrow {
        color: #181c32
    }
}

@media (max-width:991.98px) {
    .header-menu-mobile .menu-nav {
        margin: 0;
        list-style: none;
        padding: 0
    }

    .header-menu-mobile .menu-nav .menu-scroll {
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .header-menu-mobile .menu-nav .menu-subnav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        padding: 0;
        margin: 0;
        list-style: none !important
    }

    .header-menu-mobile .menu-nav .menu-inner,
    .header-menu-mobile .menu-nav .menu-submenu {
        display: none;
        float: none;
        margin: 0;
        padding: 0;
        width: auto !important
    }

    .header-menu-mobile .menu-nav .menu-inner .menu-content,
    .header-menu-mobile .menu-nav .menu-submenu .menu-content {
        padding: 0;
        margin: 0
    }

    .header-menu-mobile .menu-nav .menu-inner .menu-content>.menu-item,
    .header-menu-mobile .menu-nav .menu-submenu .menu-content>.menu-item {
        padding: 0;
        margin: 0;
        list-style: none
    }

    .header-menu-mobile .menu-nav .menu-inner .menu-content>.menu-item.menu-headingless>.menu-inner,
    .header-menu-mobile .menu-nav .menu-submenu .menu-content>.menu-item.menu-headingless>.menu-inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .header-menu-mobile .menu-nav .menu-inner .menu-content>.menu-item.menu-headingless>.menu-inner>li>.menu-link,
    .header-menu-mobile .menu-nav .menu-submenu .menu-content>.menu-item.menu-headingless>.menu-inner>li>.menu-link {
        margin: 0
    }

    .header-menu-mobile .menu-nav .menu-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        float: none;
        padding: 0
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading,
    .header-menu-mobile .menu-nav .menu-item>.menu-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        margin: 0;
        padding: 0;
        text-decoration: none;
        position: relative;
        outline: 0
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading:hover,
    .header-menu-mobile .menu-nav .menu-item>.menu-link:hover {
        text-decoration: none;
        cursor: pointer
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav .menu-item>.menu-link .menu-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        padding: 0
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading .menu-label,
    .header-menu-mobile .menu-nav .menu-item>.menu-link .menu-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        white-space: nowrap
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav .menu-item>.menu-link .menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading .menu-bullet,
    .header-menu-mobile .menu-nav .menu-item>.menu-link .menu-bullet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav .menu-item>.menu-link .menu-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        line-height: 0
    }

    .header-menu-mobile .menu-nav .menu-item>.menu-heading .menu-arrow:before,
    .header-menu-mobile .menu-nav .menu-item>.menu-link .menu-arrow:before {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .header-menu-mobile .menu-nav .menu-item.menu-item-open>.menu-heading>.menu-arrow:before,
    .header-menu-mobile .menu-nav .menu-item.menu-item-open>.menu-link>.menu-arrow:before {
        -webkit-transform: rotateZ(90deg);
        transform: rotateZ(90deg)
    }

    [direction=rtl] .header-menu-mobile .menu-nav .menu-item.menu-item-open>.menu-heading>.menu-arrow:before,
    [direction=rtl] .header-menu-mobile .menu-nav .menu-item.menu-item-open>.menu-link>.menu-arrow:before {
        -webkit-transform: rotateZ(-90deg);
        transform: rotateZ(-90deg)
    }

    .header-menu-mobile .menu-nav .menu-item.menu-item-open>.menu-inner,
    .header-menu-mobile .menu-nav .menu-item.menu-item-open>.menu-submenu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .header-menu-mobile .menu-nav .menu-item .menu-submenu .menu-item-parent {
        display: none
    }

    .header-menu-mobile .menu-nav .menu-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0 27px
    }

    .header-menu-mobile .menu-nav .menu-section.menu-section-first {
        margin-top: 0 !important
    }

    .header-menu-mobile .menu-nav .menu-section .menu-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
        padding: 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .header-menu-mobile .menu-nav .menu-section .menu-icon {
        display: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .header-menu-mobile .menu-nav .menu-separator {
        height: 0;
        overflow: hidden
    }

    .header-menu-mobile .menu-nav .menu-separator.menu-separator-marginless {
        margin: 0
    }

    .header-menu-mobile .menu-nav>.menu-item {
        position: relative;
        margin: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link {
        cursor: pointer;
        min-height: 44px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-text {
        font-weight: 400;
        font-size: 1.02rem;
        text-transform: initial
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35px;
        flex: 0 0 35px;
        font-size: 1.4rem
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-icon svg,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-icon svg {
        margin-right: -2px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-bullet,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-bullet {
        vertical-align: middle;
        text-align: right;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 15px;
        flex: 0 0 15px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-bullet>span,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-bullet>span {
        vertical-align: middle;
        display: inline-block
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        width: 4px;
        height: 4px;
        border-radius: 100%
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        width: 5px;
        height: 1px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-label,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-label {
        padding: 0 5px 0 0;
        text-align: left
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-arrow {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
        flex: 0 0 20px;
        font-size: .6rem
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-arrow:before,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-arrow:before {
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        font-family: Ki;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: ""
    }

    [direction=rtl] .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-arrow:before,
    [direction=rtl] .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-arrow:before {
        content: "" !important
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-item-here,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-item-here {
        display: none
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-arrow:before,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-arrow:before {
        -webkit-transition: all .3s ease;
        transition: all .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-subnav {
        margin: 0
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item {
        margin: 0
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link {
        cursor: pointer;
        min-height: 40px
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-text {
        font-weight: 400;
        font-size: 1rem;
        text-transform: initial
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35px;
        flex: 0 0 35px;
        font-size: 1.35rem
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon svg,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon svg {
        margin-right: -2px
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet {
        vertical-align: middle;
        text-align: right;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
        flex: 0 0 20px
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet>span {
        vertical-align: middle;
        display: inline-block
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        width: 4px;
        height: 4px;
        border-radius: 100%
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        width: 5px;
        height: 1px
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-label,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-label {
        padding: 0 5px 0 0
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-arrow {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
        flex: 0 0 20px;
        font-size: .6rem
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-arrow:before,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-arrow:before {
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        font-family: Ki;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: ""
    }

    [direction=rtl] .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-arrow:before,
    [direction=rtl] .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-arrow:before {
        content: "" !important
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-item-here,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-item-here {
        display: none
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-arrow:before,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-arrow:before {
        -webkit-transition: all .3s ease;
        transition: all .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-section {
        margin: 20px 0 0 0;
        height: 40px
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-section .menu-text {
        font-size: .8rem;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: .3px
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-separator {
        margin: 15px 0
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-separator .menu-separator-marginless {
        margin: 0
    }

    .header-menu-mobile .menu-nav>.menu-section {
        margin: 20px 0 0 0;
        height: 40px
    }

    .header-menu-mobile .menu-nav>.menu-section .menu-text {
        font-size: .83rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .3px
    }

    .header-menu-mobile .menu-nav>.menu-separator {
        margin: 15px 0
    }

    .header-menu-mobile .menu-nav>.menu-separator .menu-separator-marginless {
        margin: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-link {
        padding: 9px 30px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav .menu-content .menu-heading {
        padding: 0 30px;
        padding-right: 45px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav .menu-content .menu-inner {
        padding: 0;
        margin: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav .menu-content .menu-inner .menu-link {
        padding: 0 30px;
        padding-right: 60px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-link {
        padding: 0 30px;
        padding-right: 45px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu {
        padding: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav {
        padding: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-link {
        padding: 0 30px;
        padding-right: 60px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu {
        padding: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-link {
        padding: 0 30px;
        padding-right: 75px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-section {
        padding-right: 45px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu {
        padding: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-section {
        padding-right: 60px
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu {
        padding: 0
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-item>.menu-submenu .menu-subnav>.menu-section {
        padding-right: 75px
    }
}

@media (max-width:991.98px) {
    .header-menu-mobile {
        background-color: #fff
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-text {
        color: #3f4254
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-icon {
        color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item>.menu-link .menu-arrow {
        color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-open>.menu-link .menu-arrow {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-here>.menu-link .menu-arrow {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item.menu-item-active>.menu-link .menu-arrow {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item>.menu-arrow {
        color: #fff
    }

    .header-menu-mobile .menu-nav>.menu-section .menu-text {
        color: #7e8299
    }

    .header-menu-mobile .menu-nav>.menu-section .menu-icon {
        color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-separator {
        border-bottom: 1px solid #ebedf3
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-text {
        color: #3f4254
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon {
        color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-arrow {
        color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-arrow {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link {
        background-color: #f3f6f9
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-arrow {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
        -webkit-transition: background-color .3s;
        transition: background-color .3s
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-text,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-line>span,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-arrow,
    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow {
        color: #181c32
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-section .menu-text {
        color: #7e8299
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-section .menu-icon {
        color: #b5b5c3
    }

    .header-menu-mobile .menu-nav>.menu-item .menu-submenu .menu-separator {
        border-bottom: 1px solid #ebedf3
    }
}

@media (max-width:991.98px) {
    .header-bottom {
        z-index: 1001;
        position: fixed;
        top: 0;
        bottom: 0;
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        right: -295px;
        width: 275px
    }

    .header-bottom.header-bottom-on {
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        right: 0
    }
}

@media screen and (max-width:991.98px) and (-ms-high-contrast:active),
(max-width:991.98px) and (-ms-high-contrast:none) {
    .header-bottom {
        -webkit-transition: none !important;
        transition: none !important
    }
}

@media (max-width:991.98px) {
    .header-bottom.header-bottom-right {
        left: -295px;
        right: auto
    }

    .header-bottom.header-bottom-right.header-bottom-on {
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        left: 0;
        right: auto
    }
}

@media (max-width:991.98px) {
    .header-bottom-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        z-index: 1000;
        -webkit-animation: animation-offcanvas-fade-in .6s ease 1;
        animation: animation-offcanvas-fade-in .6s ease 1
    }
}

@media (max-width:991.98px) {
    @-webkit-keyframes animation-offcanvas-fade-in {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }

    @keyframes animation-offcanvas-fade-in {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }
}

@media (max-width:991.98px) {
    .header-bottom {
        background: #fff;
        -webkit-box-shadow: 0 1px 9px -3px rgba(0, 0, 0, .1);
        box-shadow: 0 1px 9px -3px rgba(0, 0, 0, .1)
    }
}

@media (max-width:991.98px) {
    .header-bottom-overlay {
        background: rgba(0, 0, 0, .1)
    }
}

@media (max-width:325px) {
    .header-bottom {
        width: 90% !important
    }
}

@media (max-width:991.98px) {
    [data-offcanvas-header-bottom=on] {
        overflow: hidden !important
    }
}

@media (min-width:992px) {
    .header-tabs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
        height: 100px;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .header-fixed[data-header-scroll=on] .header-tabs {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .header-tabs .nav-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        padding: 0;
        margin: 0;
        position: relative;
        text-align: center
    }

    .header-tabs .nav-item .nav-link {
        margin: 0;
        padding: .75rem 1.5rem;
        color: #fff;
        -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        background-color: #f0f3f5;
        border-left: 1px solid #e4e6ef
    }

    .header-tabs .nav-item .nav-link .nav-title {
        font-size: 1.25rem;
        color: #3f4254;
        font-weight: 600
    }

    .header-tabs .nav-item .nav-link .nav-desc {
        font-size: 1rem;
        color: #b5b5c3
    }

    .header-fixed[data-header-scroll=on] .header-tabs .nav-item .nav-link {
        border-radius: .42rem
    }

    .header-tabs .nav-item .nav-link:focus,
    .header-tabs .nav-item .nav-link:hover {
        opacity: 1;
        -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease
    }

    .header-tabs .nav-item .nav-link:focus .nav-title,
    .header-tabs .nav-item .nav-link:hover .nav-title {
        font-size: 1.25rem;
        color: #3f4254;
        font-weight: 600
    }

    .header-tabs .nav-item .nav-link:focus .nav-desc,
    .header-tabs .nav-item .nav-link:hover .nav-desc {
        font-size: 1rem;
        color: #b5b5c3
    }

    .header-tabs .nav-item .nav-link.active {
        background-color: #fff;
        opacity: 1;
        color: #181c32;
        -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
        border-left-color: transparent
        
    }

    .header-tabs .nav-item .nav-link.active{
        border-top: 5px solid #667eea;
        border-left-width: 0;
        border-right-width: 0;
        border-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%) 1 stretch;
    }
    
    .header-tabs .nav-item .nav-link.active .nav-title {
        font-size: 1.25rem;
        color: #6a71d8;
        font-weight: 600
    }

    .header-tabs .nav-item .nav-link.active .nav-desc {
        font-size: 1rem;
        color: #b5b5c3
    }

    .header-tabs .nav-item:first-child .nav-link {
        border-top-right-radius: .42rem
    }

    .header-tabs .nav-item:last-child .nav-link {
        border-left: 0;
        border-top-left-radius: .42rem
    }

    .header-navs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        background-color: #fff;
        border-bottom: 1px solid #ebedf3;
        padding: 0 40px
    }

    .header-navs .tab-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .header-navs .tab-pane {
        display: none;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .header-navs .tab-pane.active {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .header-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .header-menu .menu-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .header-menu .menu-nav>.menu-item:first-child {
        padding-right: 0
    }

    .header-menu .menu-nav>.menu-item>.menu-link {
        border-radius: .42rem;
        padding: .85rem 1.35rem
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-text {
        color: #7e8299;
        font-weight: 500;
        font-size: 1.05rem
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-arrow {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-icon i {
        color: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-icon .svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #b5b5c3
    }

    .header-menu .menu-nav>.menu-item>.menu-link .menu-icon .svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) .menu-text,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active) .menu-text {
        color: #3f4254
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) .menu-arrow,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active) .menu-arrow {
        color: #7e8299
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) .menu-icon i,
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active) .menu-icon i {
        color: #7e8299
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) .menu-icon .svg-icon svg g [fill],
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active) .menu-icon .svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #7e8299
    }

    .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) .menu-icon .svg-icon svg:hover g [fill],
    .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active) .menu-icon .svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link,
    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link {
        background-color: #f0f3f5
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-text,
    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-text {
        color: #3f4254
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-arrow,
    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-arrow {
        color: #7e8299
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon i,
    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon i {
        color: #7e8299
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon .svg-icon svg g [fill],
    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon .svg-icon svg g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease;
        fill: #7e8299
    }

    .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon .svg-icon svg:hover g [fill],
    .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon .svg-icon svg:hover g [fill] {
        -webkit-transition: fill .3s ease;
        transition: fill .3s ease
    }
}

@media (max-width:991.98px) {
    .header-tabs {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow: auto;
        /* margin: 15px */
    }

    .header-tabs .nav-item {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        white-space: nowrap
    }

    .header-tabs .nav-item .nav-link {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        white-space: nowrap;
        border-radius: .42rem;
        padding: .5rem 1rem
    }

    .header-tabs .nav-item .nav-link .nav-title {
        font-size: 1rem;
        color: #7e8299;
        font-weight: 600
    }

    .header-tabs .nav-item .nav-link .nav-desc {
        display: none
    }

    .header-tabs .nav-item .nav-link:focus,
    .header-tabs .nav-item .nav-link:hover {
        color: #3f4254;
        background-color: #f0f3f5;
        -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease
    }

    .header-tabs .nav-item .nav-link:focus .nav-title,
    .header-tabs .nav-item .nav-link:hover .nav-title {
        -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
        color: #3f4254
    }

    .header-tabs .nav-item .nav-link.active {
        color: #3f4254;
        background-color: #f0f3f5;
        -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease
    }

    .header-tabs .nav-item .nav-link.active .nav-title {
        -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
        color: #3f4254
    }
}

@media (max-width:991.98px) {
    .topbar {
        z-index: 98;
        position: fixed;
        top: 0;
        bottom: 0;
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        // height: 60px
    }

    .topbar.topbar-on {
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        right: 0
    }
}

@media screen and (max-width:991.98px) and (-ms-high-contrast:active),
(max-width:991.98px) and (-ms-high-contrast:none) {
    .topbar {
        -webkit-transition: none !important;
        transition: none !important
    }
}

@media (max-width:991.98px) {
    .topbar.topbar-top {
        top: -80px;
        right: 0;
        left: 0
    }

    .topbar.topbar-top.topbar-on {
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        top: 0;
        right: 0;
        left: 0
    }
}

@media (max-width:991.98px) {
    .topbar.topbar-bottom {
        bottom: -80px;
        right: 0;
        left: 0
    }

    .topbar.topbar-bottom.topbar-on {
        -webkit-transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        transition: right .3s ease, left .3s ease, bottom .3s ease, top .3s ease;
        bottom: 0;
        right: 0;
        left: 0
    }
}

@media (max-width:991.98px) {
    .topbar-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        z-index: 97;
        -webkit-animation: animation-offcanvas-fade-in .6s ease 1;
        animation: animation-offcanvas-fade-in .6s ease 1
    }
}

@media (max-width:991.98px) {
    @-webkit-keyframes animation-offcanvas-fade-in {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }

    @keyframes animation-offcanvas-fade-in {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }
}

@media (max-width:991.98px) {
    .topbar {
        background: #fff;
        -webkit-box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .08);
        box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .08)
    }
}

@media (max-width:991.98px) {
    .topbar-overlay {
        background: rgba(0, 0, 0, .1)
    }
}

@media (max-width:991.98px) {
    [data-offcanvas-topbar=on] {
        overflow: hidden !important
    }
}

/* .border-bottom-notificaciones{
    border-bottom: 3px solid #F4B081;
    border-left-width: 0;
    border-right-width: 0;
    border-image: linear-gradient(left, #F4B081  0%, #EF798D  100%) 1 stretch;
} */
.card-notify{
    &.bg-notify:hover{
        width: 20%;
        transition: width 0.3s;
    }
    .row-notify{
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: flex;
        flex-wrap: wrap;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        border-radius: 10px;
        position: relative;
        
    }
    .bg-notify{
        background: #DF6781;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        // width: 10%;
        width: 20%;
        transition: width 0.3s;
    }
    .text-notify{
        width: 80%;
        padding: 1.5em 1.5em 1.5em 1.5em;
    }
    .tipo-user{
        padding-bottom: 1rem;
        text-align: right;
        color: #DF6781;
        font-weight: 600;
        font-size: 1rem;
    }
    .title-notify{
        // padding: 0 1rem;
        font-size: 1rem;
        font-weight: 600;
        text-align: right;
    }
    .img-avatar {
        width: 80px;
        height: 80px;
        position: absolute;
        border-radius: 50%;
        border: 6px solid white;
        background-image: linear-gradient( -60deg, #DF6781 0%, #F9859E 100%);
        top: 50%;
        transform: translate(0, -50%);
        left: 40px;
        
    }
    path {
        fill: white;
    }
    .actions { 
        padding: 0.5rem 0rem 0rem 1rem;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: end;
        float: right;
        .label-notify{
            margin: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            height: 20px;
            font-size: 0.8rem;
            color: #80808f;
            background-color: #f3f6f9;
            padding: 0.15rem 0.75rem;
            border-radius: 0.42rem;
            cursor: pointer;
            transition: .5s;
            &:hover{
                transform: rotate(22deg);
            }
        }
    }

    // Disable
    .bg-notify.disable-bg{
        // background: #80808F;
        background-image: linear-gradient( 169deg, #a9a9bb 0%, #eef0f8 100%);
    }
    button.img-avatar.disable-bg{
        // background-image: linear-gradient( 169deg, #bab9b9  0%, #80808f  100%);
        background-image: linear-gradient( 169deg , #eef0f8 0%, #b0b0c1 100%);
    }
    .tipo-user.disable-bg{
        color: #80808F;
    }
}

.border-radius-24px{
    border-radius: 24px;
}

// @import "compass/css3";

$color-dark: #0E988E;
$width: 100%;
$width-compressed: 20%;

.sidenav{
    // position: fixed;
    width: $width;
    height: 100%;
    background-color: $color-dark;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    .main-buttons{
        list-style-type: none;
        // margin: 64px 0;
        padding: 0;
        color: #fff;
        margin-bottom: 0px;
        li{
            letter-spacing: 2px;
            font-size: 12px;
            font-weight: 600;
        }
        
        & > li{
            //   padding: 16px $width-compressed;
            padding: 8px 0px;
            //   @include box-sizing(border-box);
            cursor: pointer;
            .svg{
                width: 20%;
                text-align: center;
                path{
                    transition: fill 0.3s ease;
                    fill: #ffffff !important;
                }
            }
            &.active-list{
                .hidden{
                    width: $width - $width-compressed;
                    &:last-child {
                        // border-bottom-left-radius: 24px;
                        border-bottom-right-radius: 24px;
                    }
                }
                background-color: lighten($color-dark, 5);
                &:last-child {
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;
                }
            }
            &:hover{
                background-color: lighten($color-dark, 5);
                &:last-child {
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;
                }

            }
        }
    }
}

.hidden{
    width: 0;
    height: 100%;
    padding: 0px 0;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    list-style-type: none;
    background-color: lighten($color-dark, 5);
    // text-align: center;
    transition: none;
    // @include transition(0.3s);
    z-index: 5;
    li{
        padding: 8px 25px;
        &:hover, &:active, &:focus{
            background-color: lighten($color-dark, 9);
            
        }
    }
    // .d-none-list{
    //     display: none;
    // }
    
}

.bg-aside-notify{
    background-color: $color-dark;
}

.card-notify-2{
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    // border-radius: 10px;

    @include down-screen($md) {
        width: 100%!important;
        .card-color{
            .bg-notify{
                width: 100%!important;
            }
        }
    }

    &:hover{
        width: 100%!important;
        .card-color{
            .bg-notify{
                width: 100%!important;
            }
        }
    }
    // .card-color{
    //     border-right: 1px solid #ECECEC;
    // }
    .bg-notify{
        width: 40%;
        transition: width 2s;
        background: #DF6781;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    // .bg-success-2{
    //     border-top-right-radius: 10px;
    //     border-bottom-right-radius: 10px;
    // }
    .tipo-user{
        text-align: center;
        color: #DF6781;
        font-weight: 600;
        font-size: 12px;
    }
    .title-notify{
        font-size: 13px;
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .actions { 
            text-align: center;
            .label-notify{
                margin: 0;
                font-weight: 600;
                height: 20px;
                font-size: 0.8rem;
                color: #80808f;
                background-color: #f3f6f9;
                padding: 0.15rem 0.75rem;
                border-radius: 0.42rem;
                cursor: pointer;
                transition: .5s;
                &:hover{
                    transform: rotate(22deg);
                }
            }
    }
    .img-avatar {
        background-image: linear-gradient( -60deg, #DF6781 0%, #F9859E 100%);
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%);
        position: absolute;
        border-radius: 50%;
        padding-left: 2px;
        padding-right: 2px;
        // @include down-screen($xxxs) {
        //     width: 50px;
        //     height: 50px;
        //     border: 4px solid white;
        //     left: 31px;
        //     display: none;
        // }
        // @include screen($xxs) {
        //     width: 55px;
        //     height: 55px;
        //     border: 4px solid white;
        //     left: 40px;
        // }
        // @include screen('540px') {
        //     width: 65px;
        //     height: 65px;
        //     border: 4px solid white;
        //     left: 81px;
        // }
        // @include screen($xs) {
        //     width: 75px;
        //     height: 75px;
        //     border: 4px solid white;
        //     left: 84px;
        // }
        // @include screen($sm) {
        //     width: 53px;
        //     height: 53px;
        //     border: 4px solid white;
        //     left: 33px;
        // }
        @include down-screen($md) {
            display: none;
            // width: 55px;
            // height: 55px;
            // border: 4px solid white;
            // left: 44px;
        }
        @include screen($md) {
            width: 65px;
            height: 65px;
            border: 4px solid white;
            left: 24px;
        }
        @include screen($xl) {
            width: 70px;
            height: 70px;
            border: 4px solid white;
            left: 26px;
        }
        @include screen($xxl) {
            width: 75px;
            height: 75px;
            border: 4px solid white;
            left: 55px;
        }
        path, rect {
            fill: white;
        }
    }
    .padding-col-text{
        padding-left: 3rem;
        @include down-screen($xxxs) {
            padding-left: 0px;
        }
        @include screen($xxs) {
            padding-left: 1rem;
        }
        @include screen('540px') {
            padding-left: 2rem;
        }
        @include screen($xs) {
            padding-left: 2.5rem;
        }
        @include screen($sm) {
            padding-left: 10px;
        }
        @include screen($md) {
            padding-left: 1.5rem;
        }
        @include screen($xl) {
            padding-left: 2rem;
        }
        @include screen($xxl) {
            padding-left: 3rem;
        }
    }

    

    // Disable
    .bg-notify.disable-bg{
        background: #80808F;
        // background-image: linear-gradient( 169deg, #a9a9bb 0%, #eef0f8 100%);
    }
    button.img-avatar.disable-bg{
        background-image: linear-gradient( 169deg, #bab9b9  0%, #80808f  100%);
        // background-image: linear-gradient( 169deg , #eef0f8 0%, #b0b0c1 100%);
    }
    .tipo-user.disable-bg{
        color: #80808F;
    }
}