.close-modal{
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 1.5rem;
    color: red;
    font-weight: 450;
}

.solicitante{
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1rem;
}

.container-juntas{
    background-color: white;
    padding: 2rem;
    margin: 0;
    width: 100%;
    height: 100%;
}

.opciones{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

}

.btn-historial{
    height: 3.5rem;
    width: 120px;
    border: none;
    border-radius: 5px;
    color: #2171c1;
    font-weight: bold;
    margin-left: 1rem;
}

.btn-historial:hover{
    background-color: #2171c1;
    color: white;
    font-weight: normal;
}

.subtitle{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 450;
    margin: 0;
}

.scroll{
    overflow: scroll;
    height: 100%;
}

.modal-juntas{
    width: 100%;
    height: 100%;
    
}

.modal-juntas form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.modal-juntas form div {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 100%;
}

.modal-juntas form div input{
    width: 100%;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-juntas form div select {
    width: 100%;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.btn-reservar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border:none;
    width: 120px;
    height: 3.5rem;
    border-radius: 5px;
    color: green;
    font-weight: 600;
}

.btn-reservar:hover{
    background-color: green;
    color: white;
}

.btn-reservar span{
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0 10px 0 -10px;
}

.error{
    color: red;
}

.validate{
    color: black;
}
.asunto{
    margin: 1rem;
}

.asunto input{
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
}
.btn-reservar-sala{
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.btn-reservar-sala button{
    border:none;
    width: 120px;
    height: 2.5rem;
    border-radius: 5px;
    color: green;
    font-weight: 600;
}

.btn-reservar-sala button:hover{
    background-color: green;
    color: white;
}
.btn-acciones{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    background-color: #2171c1;
    color: white;
    border: none;
}
.align{
    display: flex;
    justify-content: center;
    align-items: center;
}

.align div a{
    cursor: pointer;
}

.table-reservas td{
    text-align: center;
}

.form-select{
    background-color: #f0f0f0;
    border: none;
    width: 10%;
}

.form-select:active{
    border: none;
}

.title{
    margin-left: 1rem;
}

.btn-aprobacion button{
    border: none;
    margin-left: 1rem;
    height: 2.5rem;
    width: 25%;
    border-radius: 5px;
}