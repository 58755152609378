.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
}

.container>div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem;
}

.containerFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.maxWidthInput {
    min-width: 10rem;
    max-width: 20rem;
}

.maxWidthInputText {
    width: 17rem;
}

.borrarButton{
    margin-top: -2rem;
    position: absolute;
    width: 7rem;
    height: 2.5rem;
    /* margin-left: 80%; */
    background: #c95a5a;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    color:#e1f0ff;
    border: none;
    border-radius: 40% 5%;
}

.borrarButton:hover {
    background: #ff4949;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.borrarButton:active {
    background: #ff4949;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.sendButton {
    position: absolute;
    margin-top: -2rem;
    width: 7rem;
    height: 2.5rem;
    margin-left: 80%;
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
}

.sendButton:hover {
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.sendButton:active {
    background: #FFD549;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}

.file {
    margin-left: 6rem;
}

.file input {
    display: none;
    cursor: pointer;
}

.file label {
    border: none;
    background-color: #3A89C9;
    color: white;
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30% 3%;
}

.file label:hover {
    background-color: #9CC4E4;
    cursor: pointer;
}

.file label:active {
    background-color: #9CC4E4;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    transform: translateY(4px);
}

.file-name {
    color: #2171c1;
    font-weight: bold;
    background-color: #e1f0ff;
    width: 150px;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
}

.nuevaRequisicion_segundoBloque{
    margin-left: 2.5rem;
}

.nuevaRequisicion{
    margin-top: 1rem;
}

.nuevaRequisicion_fecha{
    width: 130px;
}

.error{
    color: red;
}