.evento {
    padding: 0.4rem 1rem;
    border: 0px;
    text-align: center;

    i {
        color: white
    }

    overflow: hidden;
}

.fc-direction-ltr{
    height: 65vh;
}
.vacaciones {
    color: #8950FC;
    background-color: #EEE5FF;
    border-color: transparent;
    min-width: 100%;

    i {
        color: #8950FC;
    }
}

.feriados {
    color: #FFA800;
    background-color: #FFF4DE;
    border-color: transparent;

    i {
        color: #FFA800;
    }
}

.eventos {
    color: #388E3C;
    background-color: #E8F5E9;
    border-color: transparent;
    min-width: 100%;

    i {
        color: #388E3C;
    }
}

a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
    border-color: white;

}

a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-future {
    border-color: white;
    background-color: transparent !important;


}

a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-today {
    border-color: #F3F6F9;
}

.cumpleaños {
    color: #2171c1;
    background-color: #E1F0FF;
    border-color: transparent;

    i {
        color: #2171c1;
    }
}

#calendar {
    float: right;
    width: 900px;
    color: red;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #F3F6F9 !important;
}

button.fc-prev-button.btn.btn-primary {
    color: #80808F;
    background: transparent;
    border: 1px solid #ECF0F3 !important;
    text-shadow: none !important;
    height: 3.2rem;
    padding: 0 1.25rem;
    font-size: 1rem;
    border-radius: 0px 0px;
}

button.fc-next-button.btn.btn-primary {
    color: #80808F;
    background: transparent;
    border: 1px solid #ECF0F3 !important;
    text-shadow: none !important;
    height: 3.2rem;
    padding: 0 1.25rem;
    font-size: 1rem;
    border-radius: 0px 0px;
}

button.fc-today-button.btn.btn-primary {
    color: #80808F;
    background: transparent;
    border: 1px solid #ECF0F3;
    text-shadow: none !important;
    box-shadow: none !important;
    height: 3.2rem;
    padding: 0 1.25rem;
    font-size: 1rem;
    border-radius: 0px 0px;
    font-weight: 700 !important;
}

h2.fc-toolbar-title {
    font-size: 1.5rem !important;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0.75rem !important;
    color: #80808F !important;
    font-weight: 600 !important;
    white-space: nowrap;

    @include down-screen($xxs) {
        white-space: pre-line;
    }
}

a.fc-col-header-cell-cushion {
    color: #80808F !important;
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    font-weight: 700 !important;
}

.table-bordered thead th {
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    font-weight: 500;
}

.fc-h-event {
    border: transparent !important;
    background-color: transparent !important;
}

.tool-calendar {
    .tool-titulo {
        background-color: #ECEFF1;
        padding: 0.5rem;
    }

    .tool-horario {
        span {
            color: #5F6A6A;
            font-weight: bold;
        }
    }

    .tooltip-inner {
        min-width: 200px;
        width: auto;

        @include screen ($lg) {
            max-width: 30vw;
        }

        padding: 0;
    }

    .calendar-avatar {
        width: 45px;
        height: 45px;
    }
}

.calendar-avatar {
    width: 25px;
    height: auto;
    border-radius: 50%;
    padding: 1px;
    border: solid 1px #525252;
}

i.icon-green {
    color: #388E3C
}

i.icon-purple {
    color: #8950FC
}

.time-picker {
    border-top: 0px transparent !important;
    border-right: 0px transparent !important;
    border-left: 0px transparent !important;
}

.parrilla {
    .fc {
        a.fc-daygrid-day-number {
            font-size: 16px;
            margin-bottom: 4px;
            letter-spacing: 1px;
        }

        .fc-daygrid-body-unbalanced {
            .fc-daygrid-day-events {
                display: flex;
                flex-flow: wrap;
                overflow: hidden;

                .fc-daygrid-event-harness {

                    .fc-h-event {
                        border: none !important;
                        background-color: transparent !important;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

////////////////////////////////Acordion////////////////////////////////////
.accordion{
    &__toggler{
        &:hover{
            cursor: pointer;
        }
    }
}

//////////////////////////////Animation//////////////////////////////////// 
.animation{
    &__heart{
        animation: heartbeat 5s infinite;
    }
    &__comment{
        animation: comment 5s infinite;
    }
    &__people{
        animation: people 10s infinite;
    }
}

@keyframes heartbeat {
    0% { transform: scale( 1 );  opacity: .25; }
    20% { transform: scale( 1.25 ); }
    40% { transform: scale( 1 ); }
    60% { transform: scale( 1.25 ); }
    80% { transform: scale( 1 ); }
    100% { transform: scale( 1.25 ); }
}

@keyframes comment {
    0% { opacity: .5; }
    25% { opacity: 1; }
    50% { opacity: .5; }
    75% { opacity: 1; }
    100% { opacity: .5; }
}

@keyframes people{
    0% { opacity: 0.1; display: none; }
    25% { opacity: 1; }
    50% { opacity: 0.1; display: none; }
    75% { opacity: 1; }
    100% { opacity: 0.1; display: none; }
}

