.container{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.container div{
    margin-left: 20%;
}

.container div span{
    font-size: 12px;
    font-weight: bold;
    /* background-color: #8BA2C5;
    color: white; */
    color: #315694;
    padding: 3px;
    border-radius: 5px;
}

.verRequisicion{
    display: flex;
    flex-direction: row;
}
