.sendButton {
    margin-top: 1rem;
    width: 7rem;
    height: 2.5rem;
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
}

.sendButton:hover {
    background: #FFD549;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 40% 5%;

}

.sendButton:active {
    background: #FFD549;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 40% 5%;
    transform: translateY(4px);
}
