.error{
    color: red;
    font-size: medium;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2rem 20px;
    background-color: white;
    font-size: medium;
    min-height: 80vh;
}

.body>div>h3{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.body>div>div>div>form {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem 20px;
    background-color: white;
}

.body>div>div>div>form>div>label {
    margin-left: 1rem;
}

.body>div>div>div>form>div>input[type="radio"] {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.submit{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.submit>button{
    width: 10rem;
    height: 2rem;
    border-radius: 5px;
    border: none;
    background-color: #1E90FF;
    color: white;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
}

.submit>button:hover {
    background-color: #0000FF;
}

.datos_generales{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: medium;
}